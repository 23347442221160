/**与url的关联错误代码地图 https://gbd730.atlassian.net/wiki/spaces/Gaming/pages/2474049556/Platform+Web+Api+Error+Code */
export const API_ERROR_CODE_MAP: Map<string, string> = new Map([
  ['/v1/member/account/getuserinfo', 'E00001'], // '获取用户基本信息'
  ['/v1/member/account/getgooglevalidcode', 'E00002'], // '获取谷歌验证器信息'
  ['/v1/member/account/bindgooglevalid', 'E00003'], // '绑定谷歌验证器'
  ['/v1/member/account/unbindgooglevalid', 'E00004'], // '解绑谷歌验证器'
  ['/v1/member/account/verifybindmobile', 'E00005'], // '绑定手机第一步'
  ['/v1/member/account/bindmobile', 'E00006'], // '绑定手机号码（23-03-23 取消密码和Google验证第一步）'
  ['/v1/member/account/getemailverifycode', 'E00007'], // '绑定邮箱第一步'
  ['/v1/member/account/bindemail', 'E00008'], // '绑定邮箱 第二步'
  ['/v1/member/account/modifybindmobile', 'E00009'], // '修改手机第一步验证（第二步调用绑定手机号码接口）'
  ['/v1/member/account/unbindmobile', 'E00010'], // '解绑手机'
  ['/v1/member/account/unbindemail', 'E00011'], // '解绑邮箱'
  ['/v1/member/account/verifymodifyusername', 'E00012'], // '添加/修改用户名（第一步）'
  ['/v1/member/account/modifyusername', 'E00013'], // '添加/修改用户名'
  ['/v1/member/account/modifypassword', 'E00014'], // '修改密码'
  ['/v1/member/account/setpassword', 'E00015'], // '设置密码'
  ['/v1/member/account/checkwhiteliststatus', 'E00016'], // '检查是否开启了提现白名单'
  ['/v1/member/account/updatewhiteliststatus', 'E00017'], // '开启或关闭提现白名单'
  ['/v1/member/account/logout', 'E00018'], // '退出登录'
  ['/v1/member/account/modifyavatar', 'E00019'], // '修改用户头像'
  ['/v1/member/account/modifyoddsformat', 'E00020'], // '设定用户偏好赔率格式'
  ['/v1/member/account/modifyviewformat', 'E00021'], // '设定用户偏好视图格式'
  ['/v1/member/account/setdefaultcurrency', 'E00022'], // '设定用户偏好默认币种'
  ['/v1/member/account/setdefaultlanguage', 'E00023'], // '设定用户默认语言'
  ['/v1/member/account/modifycredit', 'E00024'], // '设定用户偏好抵用金'
  ['/v1/member/account/modifyinvisibilitymode', 'E00025'], // '设定用户隐身模式'
  ['/v1/member/account/getusersetting', 'E00026'], // '获取用户偏好设定'
  ['/v1/member/account/getmembersociallist', 'E00027'], // '获取用户社交账号绑定信息'
  ['/v1/member/account/socialuserunbind', 'E00028'], // '社交账号解除绑定'
  ['/v1/member/account/memberbindsocialuser', 'E00029'], // '用户登录后操作绑定社交账号'
  ['/v1/member/account/addbiometricdevice', 'E00030'], // '用户添加生物识别设备'
  ['/v1/member/account/delbiometricdevice', 'E00031'], // '用户删除生物识别设备'
  ['/v1/member/account/getmemberbiometriclist', 'E00032'], // '获取用户生物识别列表'
  ['/v1/member/account/modifybiometricdevicename', 'E00033'], // '用户修改生物识别设备名称'
  ['/v1/resource/activity/turntablerun', 'E00034'], // '转动转盘'
  ['/v1/resource/activity/getturntableinformation', 'E00035'], // '返还活动资讯'
  ['/v1/resource/activity/getmoreturntableinformation', 'E00036'], // '返还活动资讯(批量)'
  ['/v1/resource/activity/getturntablelefttimes', 'E00037'], // '取得剩余抽奖次数'
  ['/v1/resource/activity/getturntablegaptospin', 'E00038'], // '取得抽奖次数与距离下次抽奖的条件'
  ['/v1/resource/activity/getturntableprizehistory', 'E00039'], // '获取大转盘中奖历史'
  ['/v1/resource/activity/getturntableprizeshistory', 'E00040'], // '获取大转盘中奖历史(批量)'
  ['/v1/resource/activity/getturntablspinhistory', 'E00041'], // '获取大转盘转动历史'
  ['/v1/resource/activity/newuseractivityapply', 'E00042'], // '用户申请新用户红利'
  ['/v1/resource/activity/getnewuseractivityapply', 'E00043'], // '查询用户可申请的新用户红利'
  ['/v1/resource/activity/getnewranklist', 'E00044'], // '获取新竞赛活动列表'
  ['/v1/resource/activity/newrankactivityapply', 'E00045'], // '新竞赛报名'
  ['/v1/resource/activity/getnewrankresult', 'E00046'], // '新竞赛排名'
  ['/v1/resource/activity/newrankcheckapply', 'E00047'], // '新竞赛报名检查'
  ['/v1/agent/agent/dataview', 'E00048'], // '数据总览'
  ['/v1/agent/agent/datacomp', 'E00049'], // '数据对比'
  ['/v1/agent/agent/dailyview', 'E00050'], // '日佣金返还总览'
  ['/v1/agent/agent/dailyreturn', 'E00051'], // '日佣金返还好友佣金返还'
  ['/v1/agent/agent/dailyprofit', 'E00052'], // '日佣金返还产品利润'
  ['/v1/agent/agent/dailybonus', 'E00053'], // '日佣金返还客户红利'
  ['/v1/agent/agent/dailyfee', 'E00054'], // '日佣金返还存取款手续费'
  ['/v1/agent/agent/dailyadjust', 'E00055'], // '日佣金返还调账杂费'
  ['/v1/agent/agent/monthview', 'E00056'], // '月佣金返还总览'
  ['/v1/agent/agent/userlist', 'E00057'], // '用户列表'
  ['/v1/agent/agent/userremove', 'E00058'], // '会员移除'
  ['/v1/agent/agent/dailystatus', 'E00059'], // '是否开启日佣金'
  ['/v1/agent/agent/agentcommissionoverview', 'E00060'], // '联盟佣金 - 交易总览'
  ['/v1/agent/agent/agentcommissioncostinfo', 'E00061'], // '联盟佣金 - 费用明细'
  ['/v1/agent/agent/agentcommissionreturn', 'E00062'], // '联盟佣金 - 佣金返还'
  ['/v1/agent/agent/agentdividedinto', 'E00063'], // '联盟佣金 - 联盟分成'
  ['/v1/alliance/alliance/getcommissionrewardinfo', 'E00064'], // '获取返佣金奖励信息'
  ['/v1/alliance/alliance/getdefaultreferralcode', 'E00065'], // '获取默认推荐码'
  ['/v1/alliance/alliance/getreferralinfo', 'E00066'], // '获取推荐信息'
  ['/v1/alliance/alliance/getreferralrewardinfo', 'E00067'], // '获取VIP奖励信息'
  ['/v1/alliance/alliance/getrewardnotifications', 'E00068'], // '获取推荐奖励推送信息'
  ['/v1/alliance/alliance/registernewreferral', 'E00069'], // '新好友注册创建推荐关系'
  ['/v1/alliance/alliance/createreferralcode', 'E00070'], // '新增推荐码'
  ['/v1/alliance/alliance/deletereferralcode', 'E00071'], // '删除推荐码'
  ['/v1/alliance/alliance/editwelcomemessage', 'E00072'], // '更改欢迎信息'
  ['/v1/alliance/alliance/getallreferralcodes', 'E00073'], // '获取所有推荐码'
  ['/v1/alliance/alliance/getcommissionrewardslist', 'E00074'], // '获取佣金奖励列表'
  ['/v1/alliance/alliance/getreferralrewardslist', 'E00075'], // '获取推荐奖励列表'
  ['/v1/alliance/alliance/getreferralslist', 'E00076'], // '获取好友信息列表'
  ['/v1/alliance/alliance/getwelcomemessage', 'E00077'], // '获取欢迎信息'
  ['/v1/alliance/alliance/togglewelcomemessage', 'E00078'], // '开关欢迎信息'
  ['/v1/alliance/alliance/updatedefaultreferralcode', 'E00079'], // '更新默认推荐码'
  ['/v1/alliance/alliance/updatereferralcoderemark', 'E00080'], // '更新推荐码备注'
  ['/v1/alliance/alliance/sharequery', 'E00081'], // '分享页配置查询'
  ['/v1/alliance/alliance/canbind', 'E00082'], // '是否能绑定好友关系'
  ['/v1/article/announcement/getlatestarticle', 'E00083'], // '获取最新文章或最新公告(6条)'
  ['/v1/article/announcement/gethomearticle', 'E00084'], // '获取首页显示公告(最多3条)'
  ['/v1/article/announcement/getarticledetail', 'E00085'], // '获取资讯详情'
  ['/v1/article/announcement/getarticlelist', 'E00086'], // '根据主题分类ID获取公告列表'
  ['/v1/asset/appeal/gettxinfo', 'E00087'], // '根据订单号获取订单信息'
  ['/v1/asset/appeal/depositbycurrency', 'E00088'], // '法币存款申诉'
  ['/v1/asset/appeal/updatecurrencydepositorder', 'E00089'], // '更新法币存款申诉'
  ['/v1/asset/appeal/getcurrencydepositbyid', 'E00090'], // '根据Id获取法币存款申诉'
  ['/v1/asset/appeal/checktxidexists', 'E00091'], // '判断虚拟币TxId 是否存在申诉'
  ['/v1/asset/appeal/depositbycoin', 'E00092'], // '添加虚拟币存款申诉'
  ['/v1/asset/appeal/list', 'E00093'], // '获取申诉历史记录'
  ['/v1/member/auth/loginbymobile', 'E00094'], // '手机登录'
  ['/v1/member/auth/loginbyname', 'E00095'], // '用户名密码登录'
  ['/v1/member/auth/loginbyemail', 'E00096'], // '邮箱登录'
  ['/v1/member/auth/verify2fa', 'E00097'], // '登录二次验证，验证验证 2Fa'
  ['/v1/member/auth/general2faverify', 'E00098'], // '通用2Fa验证'
  ['/v1/member/auth/registerbyuserpwd', 'E00099'], // '用户名密码注册'
  ['/v1/member/auth/sendmobileverify', 'E00100'], // '发送手机 otp验证'
  ['/v1/member/auth/sendemailverify', 'E00101'], // '发送邮箱 otp验证'
  ['/v1/member/auth/registerbyemail', 'E00102'], // '邮箱注册（第二步验证opt完成）'
  ['/v1/member/auth/registerbymobile', 'E00103'], // '手机注册（第二步验证opt完成）'
  ['/v1/member/auth/resetpwdmobileverify', 'E00104'], // '找回密码验证手机OtpCode,（手机找回密码第一步）'
  ['/v1/member/auth/resetpwd', 'E00105'], // '重置密码（手机、邮箱找回密码第二步）'
  ['/v1/member/auth/resetpwdemailverify', 'E00106'], // '找回密码验证邮箱OtpCode,（邮箱找回密码第一步）'
  ['/v1/member/auth/testgetmemberid', 'E00107'], // '根据UID 查询（仅供测试）'
  ['/v1/member/auth/testgetuid', 'E00108'], // '根据UID 查询（仅供测试）'
  ['/v1/member/auth/invitecodecheck', 'E00109'], // '推荐码验证'
  ['/v1/member/auth/registerbysocial', 'E00110'], // '社交账号直接注册'
  ['/v1/member/auth/socialuserlogin', 'E00111'], // '社交账号统一登录验证'
  ['/v1/member/auth/getmetamasknonce', 'E00112'], // '获取MetaMask认证的Nonce'
  ['/v1/member/auth/socialuserbindloginbymobile', 'E00113'], // '社交账号绑定平台账号-手机登录'
  ['/v1/member/auth/loginbybiometric', 'E00114'], // 'app生物识别登录'
  ['/v1/member/auth/checkheaders', 'E00115'], // ' '
  ['/v1/api/auth/getipinfo', 'E00116'], // '根据ip定位，获取信息'
  ['/v1/api/auth/setup', 'E00117'], // '获取api 请求token'
  ['/v1/api/auth/refresh', 'E00118'], // '刷新Token,以旧换新'
  ['/v1/api/auth/getcaptcha', 'E00119'], // '初始化验证码初始化(Geetest)[已经废弃]'
  ['/v1/api/auth/getcaptchaid', 'E00120'], // '获取Botion验证码CaptchaId'
  ['/v1/api/auth/testclientinit', 'E00121'], // '模拟客户端构建获取token请求参数(仅接口调试使用）'
  ['/v1/api/auth/testrsaencode', 'E00122'], // '加密测试（仅供开发debug）'
  ['/v1/api/auth/testotpsend', 'E00123'], // '测试otp发送（仅供开发debug）'
  ['/v1/api/auth/testotpverify', 'E00124'], // '测试otp 验证（仅供开发debug）'
  ['/v1/api/auth/testip', 'E00125'], // '测试IP（仅供开发debug）'
  ['/v1/api/auth/testipinfo', 'E00126'], // '测试获取ip信息'
  ['/v1/api/auth/testquery', 'E00127'], // '测试数据库查询'
  ['/v1/asset/bankcard/getsystembank', 'E00128'], // '获取系统支持银行资讯'
  ['/v1/asset/bankcard/getbankcard', 'E00129'], // '获取银行卡'
  ['/v1/asset/bankcard/getdepositbankcard', 'E00130'], // '获取存款支付方式支持的银行卡'
  ['/v1/asset/bankcard/getbankcardverifyinfo', 'E00131'], // '获取卡号校验信息'
  ['/v1/asset/bankcard/addbankcard', 'E00132'], // '添加银行卡'
  ['/v1/asset/bankcard/updatedefault', 'E00133'], // '设为预设银行卡'
  ['/v1/asset/bankcard/deletebankcard', 'E00134'], // '删除银行卡'
  ['/v1/asset/bankcard/batchdeletebankcard', 'E00135'], // '批量删除银行卡'
  ['/v1/resource/banner/getbannerlist', 'E00136'], // '查询Banner列表'
  ['/v1/behavior/behavior/submit', 'E00137'], // '提交用户行为'
  ['/v1/asset/bonus/bonusdetail', 'E00138'], // '获取用户卡券列表'
  ['/v1/asset/bonus/bonussort', 'E00139'], // '抵扣券排序'
  ['/v1/asset/bonus/bonusflow', 'E00140'], // '获取抵用金使用详情'
  ['/v1/asset/bonus/receivebackwater', 'E00141'], // '领取卡券'
  ['/v1/asset/bonus/batchreceivebonus', 'E00142'], // '一键领取卡券'
  ['/v1/asset/bonus/querybackwaterlist', 'E00143'], // '获取反水数据'
  ['/v1/asset/bonus/getbonusselect', 'E00144'], // '获取卡券状态，下拉列表'
  ['/v1/asset/bonus/getbonustypelist', 'E00145'], // '获取卡券类型，下拉列表'
  ['/v1/asset/bonus/getbonusactivity', 'E00146'], // '法币存款页面， 查询用户能参与的活动'
  ['/v1/asset/bonus/getcoupondeposit', 'E00147'], // '法币存款页面，通过券码存款code，获取红利活动'
  ['/v1/asset/bonus/getactivityinfo', 'E00148'], // '首页展示：获取活动列表'
  ['/v1/asset/bonus/getactivitydetail', 'E00149'], // '首页展示：查询活动详情'
  ['/v1/asset/bonus/bonuscustomerapply', 'E00150'], // '用户报名活动'
  ['/v1/asset/bonus/bonuscustomerstatus', 'E00151'], // '用户报名活动状态'
  ['/v1/asset/bonus/getbonusgrantlist', 'E00152'], // '钱包历史记录 > 卡券领取记录'
  ['/v1/asset/bonus/getgranttypeselect', 'E00153'], // '卡券发放方式，下拉列表'
  ['/v1/asset/bonus/getbonuscount', 'E00154'], // '获取卡券可领取的数量'
  ['/v1/asset/bonus/exchangereceive', 'E00155'], // '券码兑换优惠券'
  ['/v1/asset/bonus/exchangereceiveinfo', 'E00156'], // '优惠券记录查询'
  ['/v1/asset/bonus/getcontestactivities', 'E00157'], // '获取进行中的竞赛活动'
  ['/v1/asset/bonus/getrank', 'E00158'], // '根据活动编号查询用户的排名'
  ['/v1/asset/bonus/getrankbyid', 'E00159'], // '获取用户在活动个人排名 (我的排名)'
  ['/v1/asset/bonus/getactivitybaseinfo', 'E00160'], // '竞猜活动 ==》取得活动基础资讯 (活动header顶部)'
  ['/v1/asset/bonus/getbonussetting', 'E00161'], // '竞猜活动 ==》查询活动奖励范围 (活动header中间部分)'
  ['/v1/asset/bonus/getactivityoptions', 'E00162'], // '取得指定活动的所有选项'
  ['/v1/asset/bonus/getrecentactivity', 'E00163'], // '查询最近活动'
  ['/v1/asset/bonus/gethistorydetail', 'E00164'], // '竞猜活动 ==》取得指定使用者参与且已结算的活动详细记录 （历史竞猜）'
  ['/v1/asset/bonus/gethistorylist', 'E00165'], // '竞猜活动 ==》取得指定使用者的参与列表 （历时竞猜下拉框）'
  ['/v1/asset/bonus/getuseroptions', 'E00166'], // '竞猜活动 ==》取得指定使用者是否已参与和选择项目的资讯 (当前竞猜)'
  ['/v1/asset/bonus/gethistorydetailwithlist', 'E00167'], // '取得指定使用者的参与列表'
  ['/v1/asset/bonus/setuseroptions', 'E00168'], // '竞猜活动 ==》添加或更新使用者的选择 (提交预测)'
  ['/v1/asset/bonus/getleaderboard', 'E00169'], // '竞猜活动 ==》取得指定活动ID的排行榜 （排行榜列表）'
  ['/v1/asset/bonus/getleaderboardlist', 'E00170'], // '竞猜活动 ==》取得排行榜列表 （排行榜列表下拉框）'
  ['/v1/asset/bonus/getlater', 'E00171'], // '获取后发现金券信息'
  ['/v1/asset/bonus/currencytorate', 'E00172'], // '法币存款页面==>转换 范围币种 计算金额'
  ['/v1/asset/bonus/getactivitycontent', 'E00173'], // '通过活动编号获取活动内容'
  ['/v1/asset/bonus/paymentiqdepositbonus', 'E00174'], // '提交PIQ存款红利'
  ['/v1/asset/bonus/cryptodepositbonus', 'E00175'], // '提交虚拟币存款红利'
  ['/v1/article/category/getcategoryinfo', 'E00176'], // '查询资讯主题分类(常见问题、公告)'
  ['/v1/resource/country/getall', 'E00177'], // '获取所有国家'
  ['/v1/asset/deposit/currency', 'E00178'], // '法币存款（统一一个接口）'
  ['/v1/asset/deposit/tocurrency', 'E00179'], // '存虚得法 存款'
  ['/v1/asset/deposit/depositaddress', 'E00180'], // '虛擬貨幣存款地址(虚拟币存款)'
  ['/v1/asset/deposit/currencypurchase', 'E00181'], // '获取购买币种链接'
  ['/v1/asset/deposit/getorderstateinfo', 'E00182'], // '获取订单状态'
  ['/v1/article/faq/gettagselect', 'E00183'], // '展示标签下拉列表'
  ['/v1/article/faq/getarticlebytag', 'E00184'], // '根据标签获取相关资讯(最多5条)'
  ['/v1/article/faq/gethotfaq', 'E00185'], // '获取热门问题或热门文章(最多6条)'
  ['/v1/article/faq/getarticlelist', 'E00186'], // '根据主题分类ID获取问题列表'
  ['/v1/article/faq/getarticledetail', 'E00187'], // '获取资讯详情及相关文章(最多5条)'
  ['/v1/resource/footer/getfooterlist', 'E00188'], // '查询footer列表'
  ['/v1/resource/footer/footertypelist', 'E00189'], // '页尾类型下拉'
  ['/v1/agent/friend/dataview', 'E00190'], // '数据总览'
  ['/v1/agent/friend/commissionreturn', 'E00191'], // '佣金返还'
  ['/v1/agent/friend/commissioninvite', 'E00192'], // '推荐返还'
  ['/v1/agent/friend/commissiontrend', 'E00193'], // '佣金返还走势'
  ['/v1/agent/friend/getuserapply', 'E00194'], // '获取转入申请'
  ['/v1/agent/friend/setuserapply', 'E00195'], // '确认转入申请'
  ['/v1/agent/friend/agentapply', 'E00196'], // '代理申请'
  ['/v1/agent/friend/gametypes', 'E00197'], // '获取游戏类型列表'
  ['/v1/agent/friend/isagent', 'E00198'], // '当前登录是否代理'
  ['/v1/agent/friend/userapplylist', 'E00199'], // '转入申请查询'
  ['/v1/agent/friend/userapplycheck', 'E00200'], // '转入申请验证'
  ['/v1/agent/friend/userapplycreate', 'E00201'], // '转入申请'
  ['/v1/agent/friend/agentapplystatus', 'E00202'], // '获取代理申请状态'
  ['/v1/agent/friend/commissiontopdate', 'E00203'], // '顶级推荐人排行榜-按日期查询'
  ['/v1/agent/friend/commissiontopreal', 'E00204'], // '顶级推荐人排行榜-实时排名'
  ['/v1/agent/friend/commissiontopyesterday', 'E00205'], // '顶级推荐人排行榜-昨日'
  ['/v1/agent/friend/commissiontopone', 'E00206'], // '顶级推荐人排行榜-个人排行情况'
  ['/v1/agent/friend/ftdlist', 'E00207'], // '首存概括'
  ['/v1/agent/friend/transactionlist', 'E00208'], // '游戏记录'
  ['/v1/agent/friend/userstatlist', 'E00209'], // '会员管理'
  ['/v1/game/provider', 'E00210'], // '厂商列表 返回内容Sort排序'
  ['/v1/game/providerbylabel', 'E00211'], // '根据标签获取厂商列表 返回内容 A-Z排序'
  ['/v1/game/getlabelbyproviderid', 'E00212'], // '根据厂商获取标签'
  ['/v1/game/gamelistbylabel', 'E00213'], // '游戏列表-标签分类 预设每个标签显示20个游戏'
  ['/v1/game/getlabelandgamelistbyscenes', 'E00214'], // '获取场景标签和游戏列表'
  ['/v1/game/getscenesmenu', 'E00215'], // '获取场景标签'
  ['/v1/game/getscenesgamelistbylabelids', 'E00216'], // '获取场景游戏 通过标签Id'
  ['/v1/game/gamemultiplelabel', 'E00217'], // '游戏页面--多个标签id获取--为你推荐数据，预设25个，上限50个 有写死的标签Id，即将废除'
  ['/v1/game/gamemultiplelabelold', 'E00218'], // '游戏页面--多个标签id获取--为你推荐数据，预设25个，上限50个 有写死的标签Id，即将废除'
  ['/v1/game/gamelistbyprovider', 'E00219'], // '游戏列表-游戏供应商分类'
  ['/v1/game/getgameinfo', 'E00220'], // '获取游戏资料及投注级距配置'
  ['/v1/game/search', 'E00221'], // '搜索'
  ['/v1/game/sortselect', 'E00222'], // '排序下拉'
  ['/v1/game/gamelabel', 'E00223'], // '游戏标签下拉'
  ['/v1/game/getlabelsceneslist', 'E00224'], // '场景标签列表'
  ['/v1/game/providercategory', 'E00225'], // '游戏类型下拉(交易记录)'
  ['/v1/game/gametype', 'E00226'], // '游戏类型下拉(游戏首页)'
  ['/v1/game/playgame', 'E00227'], // '取得游戏连结'
  ['/v1/game/favoritegame', 'E00228'], // '获取收藏游戏'
  ['/v1/game/addfavoritegame', 'E00229'], // '添加收藏游戏'
  ['/v1/game/removefavoritegame', 'E00230'], // '移除收藏游戏'
  ['/v1/game/getrecentlyplayed', 'E00231'], // '最近玩过'
  ['/v1/game/isdisablegame', 'E00232'], // '用户是否被厂商禁用玩游戏'
  ['/v1/game/getbetrangesetting', 'E00233'], // '获取投注级距配置'
  ['/v1/game/gethotmatch', 'E00234'], // '获取热门游戏'
  ['/v1/game/getproviderdetail', 'E00235'], // '获取游戏供应商详情'
  ['/v1/game/thirdpartygame', 'E00236'], // '获取进入游戏的html'
  ['/v1/asset/gameorder/getwagerstatusselect', 'E00237'], // '查询订单状态下拉列表'
  ['/v1/asset/gameorder/getsportlist', 'E00238'], // '查询体育游戏订单列表'
  ['/v1/asset/gameorder/getsportdetail', 'E00239'], // '查询体育游戏订单详情'
  ['/v1/asset/gameorder/getcasinolist', 'E00240'], // '查询娱乐城订单列表'
  ['/v1/asset/gameorder/getcasinodetail', 'E00241'], // '查询娱乐城订单详情'
  ['/v1/asset/gameorder/getchesslist', 'E00242'], // '查询棋牌游戏订单列表'
  ['/v1/asset/gameorder/getchessdetail', 'E00243'], // '查询棋牌游戏订单详情'
  ['/v1/asset/gameorder/getlotterylist', 'E00244'], // '查询彩票游戏订单列表'
  ['/v1/asset/gameorder/getlotterydetail', 'E00245'], // '查询彩票游戏订单详情'
  ['/v1/asset/gameorder/recentorder', 'E00246'], // '最近交易记录'
  ['/v1/asset/gameorder/getwagerdaytotal', 'E00247'], // '会员注单日期汇总'
  ['/v1/asset/gameorder/getwagerlist', 'E00248'], // '统一注单列表'
  ['/v1/asset/gameorder/getselfrealtimebetinfo', 'E00249'], // '我的投注初始化'
  ['/v1/asset/gameorder/getrealtimebetinfo', 'E00250'], // '所有投注初始化'
  ['/v1/asset/gameorder/getherobetinfo', 'E00251'], // '风云榜'
  ['/v1/asset/gameorder/getluckiestbetinfo', 'E00252'], // '最幸运'
  ['/v1/asset/gameorder/getmostwinerbetinfo', 'E00253'], // '大赢家'
  ['/v1/asset/gameorder/getluckywinerbetinfo', 'E00254'], // '幸运赢家'
  ['/v1/member/history/loginhistory', 'E00255'], // '登录历史记录（最长180天历史记录）'
  ['/v1/member/history/operationhistory', 'E00256'], // '操作记录（最长180天历史记录）'
  ['/v1/member/history/devices', 'E00257'], // '获取用户设备列表'
  ['/v1/member/history/getdevicelog', 'E00258'], // '获取设备的操作记录'
  ['/v1/member/history/deletedevice', 'E00259'], // '删除设备'
  ['/v1/asset/history/cointx', 'E00260'], // '数字货币存款/提款交易'
  ['/v1/asset/history/currencytx', 'E00261'], // '法币存款/提款交易'
  ['/v1/asset/history/hasdeposittx', 'E00262'], // '判断用户是否有成功的存款订单'
  ['/v1/asset/history/transferwalletselect', 'E00263'], // '划转钱包下拉列表'
  ['/v1/asset/history/transfer', 'E00264'], // '划转交易记录'
  ['/v1/asset/history/getledexchange', 'E00265'], // 'LED兑换记录'
  ['/v1/asset/history/wager', 'E00266'], // '投注交易记录'
  ['/v1/asset/history/gamecategory', 'E00267'], // '游戏类型选项'
  ['/v1/asset/history/status', 'E00268'], // '存/提款状态下拉'
  ['/v1/asset/history/wagerstatus', 'E00269'], // '投注交易状态下拉'
  ['/v1/asset/history/adjusttx', 'E00270'], // '调账记录'
  ['/v1/agent/invite/create', 'E00271'], // '创建推荐链接'
  ['/v1/agent/invite/setdefault', 'E00272'], // '设置为默认推荐链接'
  ['/v1/agent/invite/updateremark', 'E00273'], // '更新推荐链接备注'
  ['/v1/agent/invite/list', 'E00274'], // '推荐链接列表'
  ['/v1/agent/invite/relation', 'E00275'], // '推荐链接下好友列表'
  ['/v1/agent/invite/getdefault', 'E00276'], // '获取默认推荐链接'
  ['/v1/agent/invite/getshareimage', 'E00277'], // '获取分享图片'
  ['/v1/member/kyc/kyc', 'E00278'], // '查询用户KYC状态'
  ['/v1/member/kyc/memberkyclimit', 'E00279'], // '查询用户KYC限额'
  ['/v1/member/kyc/getkycsettings', 'E00280'], // '取得KYC相关设定'
  ['/v1/member/kyc/primary', 'E00281'], // '初级验证'
  ['/v1/member/kyc/intermediate', 'E00282'], // '中级验证(中国)'
  ['/v1/member/kyc/globalintermediate', 'E00283'], // '中级验证(国外)'
  ['/v1/member/kyc/advanced', 'E00284'], // '高级验证'
  ['/v1/member/kyc/country', 'E00285'], // '查询jumio支持的验证类型'
  ['/v1/member/kyc/updatestatus', 'E00286'], // '中级验证状态更新接口，APP提交外国籍中级认证资料给第三方后呼叫'
  ['/v1/member/kyc/sendsms', 'E00287'], // '發送驗證碼'
  ['/v1/member/kyc/verifysms', 'E00288'], // '验证码校验'
  ['/v1/member/kyc/kycinfo', 'E00289'], // '获取Kyc信息'
  ['/v1/member/kyc/getlivecheckconnect', 'E00290'], // '获取活体验证连接'
  ['/v1/member/kyc/updatebasicinfoforeu', 'E00291'], // 'KYC修改用户  EU'
  ['/v1/member/kyc/intermediateidcardforeu', 'E00292'], // 'KYC中级认证(ID)  EU'
  ['/v1/member/kyc/intermediatepoaforeu', 'E00293'], // 'KYC中级认证(POA) EU'
  ['/v1/member/kyc/primaryforeu', 'E00294'], // 'KYC初级认证  EU'
  ['/v1/member/kyc/processdetailforeu', 'E00295'], // 'kyc审核详情  EU'
  ['/v1/member/kyc/queryuserverificationforeu', 'E00296'], // 'KYC用户查询认证 EU'
  ['/v1/member/kyc/getmemberbasicinfo', 'E00297'], // '获取用户的基本信息'
  ['/v1/member/kyc/queryauthenticateforeu', 'E00298'], // '获取用户待完成的认证'
  ['/v1/member/kyc/kycadvancedforeu', 'E00299'], // '用户提交高级认证'
  ['/v1/member/kyc/cacheprimaryinfo', 'E00300'], // '缓存用户初级kyc信息（仅临时保存）'
  ['/v1/member/kyc/getprimarycacheinfo', 'E00301'], // '获取用户初级kyc信息'
  ['/v1/resource/language/getalllanguage', 'E00302'], // '获取所有语言'
  ['/v1/resource/language/gettranslations', 'E00303'], // '获取翻译（后期前端读取静态翻译资源文件）'
  ['/v1/resource/language/set', 'E00304'], // '更换语言'
  ['/v1/resource/merchant/getconfig', 'E00305'], // '查询商户配置信息'
  ['/v1/member/negativeclear/apply', 'E00306'], // '申请'
  ['/v1/member/negativeclear/query', 'E00307'], // '查询'
  ['/v1/member/negativeclear/detail', 'E00308'], // '详情'
  ['/v1/asset/nonsticky/activatewallet', 'E00309'], // '激活钱包'
  ['/v1/asset/nonsticky/cancelwallet', 'E00310'], // '放弃钱包'
  ['/v1/asset/nonsticky/withdrawwallet', 'E00311'], // '提现钱包'
  ['/v1/asset/nonsticky/batchwithdrawwallet', 'E00312'], // '一键领取提现非粘性奖金'
  ['/v1/asset/nonsticky/walletoverview', 'E00313'], // '钱包总览'
  ['/v1/asset/nonsticky/getactivated', 'E00314'], // '获取已激活的非粘性奖金'
  ['/v1/asset/nonsticky/getlist', 'E00315'], // '获取未激活的非粘性奖金'
  ['/v1/asset/nonsticky/getdetail', 'E00316'], // '获取非粘性奖金条款'
  ['/v1/asset/nonsticky/getcount', 'E00317'], // '获取数量'
  ['/v1/asset/refdata/currencies', 'E00318'], // '获取所有币种'
  ['/v1/asset/refdata/tokennetworks', 'E00319'], // '获取所有虚拟货币和网络'
  ['/v1/asset/refdata/allnetworks', 'E00320'], // '获取所有虚拟货币网络'
  ['/v1/asset/refdata/getrate', 'E00321'], // '获取币种汇率'
  ['/v1/asset/refdata/getpaymentlist', 'E00322'], // '法币存/取款支付方式'
  ['/v1/asset/refdata/getexchangerate', 'E00323'], // '兑换汇率（兑换货币场景使用）'
  ['/v1/asset/refdata/getfiattovirtualrate', 'E00324'], // '获取法币对应所有虚拟币汇率（存虚得法使用）'
  ['/v1/asset/refdata/getewalletpaymentlist', 'E00325'], // '获取电子钱包支付方式'
  ['/v1/riskform/riskform/submitriskassessment', 'E00326'], // '提交风险评估问卷'
  ['/v1/riskform/riskform/submitwealthsource', 'E00327'], // '提交财富来源证明'
  ['/v1/riskform/riskform/submitfullaudit', 'E00328'], // '提交全套审核'
  ['/v1/riskform/riskform/querynormalriskform', 'E00329'], // '查询用户是否有待完成的风控表单'
  ['/v1/riskform/riskform/queryriskabnormalmember', 'E00330'], // '查询用户是否在风控列表中'
  ['/v1/riskform/riskform/queryauthentication', 'E00331'], // '查询用户认证信息'
  ['/v1/riskform/riskform/getlastauditriskform', 'E00332'], // '获取最近一次审核的风控表单详情'
  ['/v1/riskform/riskform/getrequestdocument', 'E00333'], // '获取用户上传的文档'
  ['/v1/riskform/riskform/uploadidverification', 'E00334'], // '上传Id'
  ['/v1/riskform/riskform/uploadproofofaddress', 'E00335'], // '上传poa'
  ['/v1/riskform/riskform/uploadpaymentmethod', 'E00336'], // '上传支付方式'
  ['/v1/riskform/riskform/uploadcustomize', 'E00337'], // '上传自定义文件'
  ['/v1/riskform/riskform/uploadsow', 'E00338'], // '上传补充文件-财富来源证明'
  ['/v1/riskform/riskform/submitedd', 'E00339'], // '提交风险评估问卷'
  ['/v1/article/search/getlist', 'E00340'], // '获取搜索资讯结果'
  ['/v1/article/support/getarticleinfo', 'E00341'], // '根据资讯标识,获取资讯详情'
  ['/v1/article/support/getstandardbycode', 'E00342'], // '根据前端标识 获取常规文章'
  ['/v1/article/support/getstandardbyid', 'E00343'], // '根据文章id 获取常规文章'
  ['/v1/asset/tokenaddress/gettokenaddress', 'E00344'], // '获取数字货币地址'
  ['/v1/asset/tokenaddress/addtokenaddress', 'E00345'], // '添加数字货币地址'
  ['/v1/asset/tokenaddress/addewalletaddress', 'E00346'], // '添加电子钱包地址'
  ['/v1/asset/tokenaddress/batchdelete', 'E00347'], // '批量删除加密货币地址(支持单个)'
  ['/v1/asset/tokenaddress/batchupdatewhitelist', 'E00348'], // '批量加入白名单/移出白名单(支持单个)'
  ['/v1/resource/upload/createuploadurl', 'E00349'], // '生成上传接口地址（只允许.gif, .png, .bmp, .jpg, .pdf,.mp3,.mp4,.mov,.rmvb 文件上传）'
  ['/v1/sitemail/userfeedback/query', 'E00350'], // '查询用户意见反馈'
  ['/v1/sitemail/userfeedback/get', 'E00351'], // '获取用户意见反馈详情'
  ['/v1/sitemail/userfeedback/create', 'E00352'], // '新增用户意见反馈'
  ['/v1/sitemail/userfeedback/getoptionlist', 'E00353'], // '获取建议类型/产品类型/客户端类型列表'
  ['/v1/sitemail/usernotice/getnoticeconfig', 'E00354'], // '获取会员站内信设定'
  ['/v1/sitemail/usernotice/setnoticelanguage', 'E00355'], // '设定通知语言'
  ['/v1/sitemail/usernotice/setreceivenoticetypelist', 'E00356'], // '设定接收通知类型'
  ['/v1/sitemail/usernotice/getnoticecount', 'E00357'], // '获取各通知类型未读数量'
  ['/v1/sitemail/usernotice/getnoticelist', 'E00358'], // '获取最新n条未读通知'
  ['/v1/sitemail/usernotice/querynotice', 'E00359'], // '查询通知'
  ['/v1/sitemail/usernotice/readnotice', 'E00360'], // '标记已读'
  ['/v1/sitemail/usernotice/deletenotice', 'E00361'], // '删除通知'
  ['/v1/sitemail/usernotice/readall', 'E00362'], // '一键已读指定类型或全部未读'
  ['/v1/sitemail/usernotice/deleteall', 'E00363'], // '一键删除指定类型或全部已读'
  ['/v1/member/vipa/getuserdetail', 'E00364'], // '用户VIP等级'
  ['/v1/member/vipa/getlevelsimplelist', 'E00365'], // '等级列表(等级)'
  ['/v1/member/vipa/getleveldetaillist', 'E00366'], // 'VIPA福利配置接口'
  ['/v1/member/vipa/gettemplateinfo', 'E00367'], // '模板详情'
  ['/v1/member/vipc/getuserdetail', 'E00368'], // '会员详情'
  ['/v1/member/vipc/getleveldetaillist', 'E00369'], // '等级配置列表'
  ['/v1/member/vipc/getlevelgrouplist', 'E00370'], // '等级分组列表'
  ['/v1/member/vipc/getlevelpointslist', 'E00371'], // '积分配置列表'
  ['/v1/member/vipold/uservip', 'E00372'], // '查询用户VIP'
  ['/v1/member/vipold/vipsetting', 'E00373'], // '查询VIP等级设定'
  ['/v1/member/vipold/vipbenefits', 'E00374'], // 'VIP福利列表'
  ['/v1/asset/wallet/overview', 'E00375'], // '钱包总览'
  ['/v1/asset/wallet/mainwallet', 'E00376'], // '主钱包'
  ['/v1/asset/wallet/userbalance', 'E00377'], // '用户所有币种余额'
  ['/v1/asset/wallet/checkpaymentavail', 'E00378'], // '验证用户是否可以使用支付方式（调整改为正式数据）'
  ['/v1/asset/wallet/transferwallet', 'E00379'], // '划转'
  ['/v1/asset/wallet/gettransferwallet', 'E00380'], // '钱包划转列表'
  ['/v1/asset/wallet/transerwalletbalance', 'E00381'], // '获取转账钱包余额'
  ['/v1/asset/wallet/gamewallethistory', 'E00382'], // '钱包总览--> 转账钱包明细'
  ['/v1/asset/wallet/getgamewallet', 'E00383'], // '根据厂商id获取钱包信息'
  ['/v1/asset/wallet/getcommissionhistory', 'E00384'], // '钱包历史记录 --> 查询佣金历史'
  ['/v1/asset/wallet/getreturntypeselect', 'E00385'], // '佣金类型下拉选单'
  ['/v1/asset/wallet/forbidpaymentinfo', 'E00386'], // '判断是否禁用提款/存款'
  ['/v1/asset/wallet/clearcredit', 'E00387'], // '清除抵用券'
  ['/v1/asset/wallet/getclearwithdrawallimitcurrency', 'E00388'], // '获取可以进行 清除提款限额 的币种'
  ['/v1/asset/wallet/clearwithdrawallimit', 'E00389'], // '清除提款限额'
  ['/v1/asset/wallet/allowwithdrawal', 'E00390'], // '准许提款'
  ['/v1/asset/wallet/receiveled', 'E00391'], // '领取LED金额'
  ['/v1/asset/wallet/ledexchange', 'E00392'], // 'LED兑换其他币种'
  ['/v1/asset/withdraw/currceny', 'E00393'], // '法币提款'
  ['/v1/asset/withdraw/coin', 'E00394'], // '虚拟币提款'
  ['/v1/asset/withdraw/currcencytocoin', 'E00395'], // '提法得虚提款'
  ['/v1/asset/withdraw/getquotalimit', 'E00396'], // '获取提款限额'
  ['/v1/asset/withdraw/cancelcurrency', 'E00397'], // '取消法币提款订单'
  ['/v1/asset/withdraw/riskformverify', 'E00398'], // '检查是否可以提款成功 true:可以提款, false:不能提款'
  ['/v2/game/game/playgame', 'E00400'], // '取得游戏连结'
  ['/v2/game/game/getrecentlyplayed', 'E00401'], // '最近玩过'
  ['/v2/game/game/favoritegame', 'E00402'], // '获取收藏游戏'
  ['/v2/game/game/getscenesgamelistbylabelids', 'E00403'], // '获取场景游戏 通过标签Id'
  ['/v2/game/game/getscenesmenu', 'E00404'], // '获取场景标签'
  ['/v2/wallet/wallet/checkpaymentavail', 'E00405'], // '验证用户是否可以使用支付方式（调整改为正式数据）'
  ['/v1/resource/system/getsystemconfig', 'E00406'], // '获取各种商户开关设定'
  ['/version', 'E00900'], // 'sit、uat环境才会请求的接口，用于获取后端的版本'
  ['/v1/connect', 'E00901'], // '测试域名是否有效的特殊接口'
  ['_tenant_json_', 'E00902'], // '获取商户配置的json' RegExp: /configs\d\/web-\d\.json$/
  ['_translate_json_', 'E00903'], // '获取语言翻译的json' RegExp: /configs\d\/LanguageTranslate\/.*\.json$/
  ['_unknown_put_', 'E00904'], // 专属于put类型且无固定地址的请求错误，目前已知的情景：1、上传图片时，用后端给来的地址进行第三方put请求
  ['/im/api/auth/generateToken', 'E00905'], // 获取chat令牌
  ['/im/api/file/upload', 'E00906'], // chat文件上传
  ['/v1/member/selfrestraint/getdepositlimit', 'E00907'], // 用户存款限额
  ['/v1/member/selfrestraint/modifydepositlimit', 'E00908'], // 更新用户存款限额
  ['/v1/member/selfrestraint/canceldepositlimitpreset', 'E00909'], // 删除用户存款限额预设
  ['/v1/member/selfrestraint/setselfexclusion', 'E00910'], // 用户自我排除设定
]);

/**正则类型的url映射 */
export const URL_REGEXP_MAP: {
  reg: RegExp;
  key: string;
}[] = [
  { reg: /configs\d*\/(uat\/)*web-\d*\.json$/, key: '_tenant_json_' },
  { reg: /configs\d*\/LanguageTranslate\/.*\.json$/, key: '_translate_json_' },
];
