import { commonEnvironment } from './environment.common';
import { orignalEnvironmentPord } from './orignal/orignal-environment.prod';

const apiUrl = window.location.origin;

export const environment = {
  production: true,
  common: {
    tenant: '1',
    ...commonEnvironment,
  },
  version: 'sit.20241114.1',
  /**是否为prod环境 */
  //@ts-ignore
  isOnline: 'false' == 'true',
  /**是否是sit环境 */
  //@ts-ignore
  isSit: 'true' == 'true',
  /**是否是uat环境 */
  //@ts-ignore
  isUat: 'false' == 'true',
  apiUrl: apiUrl,
  resourceUrl: '#{resourceUrl}#',
  signalrUrl: apiUrl + '/ws',
  chatSignalrUrl: apiUrl + '/im/ws',
  rsaPublicKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1lcU5lRpSOqdLicIimSso8wSCTDWdtv3BXGeixALS+bcqOMmV2Tm5F5O3sOAku/a+XxeC+yXkaVrCXpgsl0LEPGVnqO5XoVs4LTeo0zwCJQ+H7TN1ZlqkpfFCL7Mn1+dUXvy+N2p5ijlTZiFsfetc+Jr/JH2Zj62nnc/Vpxne0RsKLwh4Mwp6i/BSv2H9xurablJpz3GPb0qoTniCuxzXvCR9h2tFfbCNacrdpOFVW/A8g27g5em+uqjVB5xAhM1pj0b5PlgR6Oyn5c5mmK1waBx/P+NZJRmGrDbHZMq07v3ma9LTOCGGoG90ReYHxVFRSlAzfl5NGF9nrkfZW4skQIDAQAB',
  /**原创相关 */
  orignal: orignalEnvironmentPord,
  cacheKey: '3',
  /**是否为App */
  isApp: false,
  translateUrl: '/configs3/LanguageTranslate/Web/[lang].json,/configs2/LanguageTranslate/Web/[lang].json,/configs/LanguageTranslate/Web/[lang].json',
  configUrls: '/configs3/web-[tenant].json,/configs2/web-[tenant].json,/configs/web-[tenant].json',
  sentryDsn: 'https://dff2ed5deeb9e10164ae35ec4a082539@sentry.athena25.com/2',
  /**是否为欧洲版 */
  //@ts-ignore
  isEur: 'false' == 'true',
};
